import React, { useState, useEffect } from 'react'
import SkillsBar from './SkillsBar'
import { useNavigate } from 'react-router-dom'
import Feedback from '../details/Feedback'
import { useSelector, useDispatch } from 'react-redux'
import { setQuizPoints } from '../../../redux-features/user/userSlice'
import { Grid, useMediaQuery, Box } from "@mui/material"
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { ProgressBar } from 'react-bootstrap';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook } from '@fortawesome/free-solid-svg-icons'
import Notes from '../notes/Notes'
import { t } from 'i18next'
import { useTheme } from '@mui/material/styles'
import ChatBot from '../../common/chatbot'

export default function SkillQuiz() {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [nextSrc, setNextSrc] = useState("../../assets/svg/next.svg")
    const [backSrc, setBackSrc] = useState("../../assets/svg/prev-outlined.svg")
    const [isComplete, setIsComplete] = useState(false)
    const [content, setContent] = useState()
    const [value, setValue] = useState(0);
    const [points, setPoints] = useState(0)
    const [answers, setAnswers] = useState([])
    const [submit, setSubmit] = useState([])
    const [ind, setInd] = useState(0)
    const [skillComplete, setSkillComplete] = useState();
    const temp = useSelector(state => state?.user?.quiz)
    const skillSections = useSelector(state => state?.user?.skillSections)
    const moduleID = useSelector(state => state.user?.moduleID)
    const subSkillID = useSelector(state => state.user?.subSkillID)
    const cModule = useSelector(state => state.user?.module)
    const userCompleted = useSelector((state) => state?.user?.userCompleted);
    const [showModal, setShowModal] = useState(false);
    const [questionFlag, setQuestionFlag] = useState(true)
    const openModal = () => {
        setShowModal(!showModal);
    };
    useEffect(() => {
        setContent(temp)
        if (skillSections) {
            if (userCompleted?.includes(subSkillID)) {
                setSkillComplete(true)
                temp?.map(que => {
                    setSubmit(old => [...old, true])
                    setAnswers(old => [...old, que.trueOption])
                })
                if (temp?.length === 0) {
                    if (userCompleted?.includes(cModule._id)) {
                        navigate("/lesson?id=" + moduleID)
                    } else {
                        navigate("/lesson?id=" + moduleID)
                    }
                }
            } else {
                setSkillComplete(false)
                if (temp?.length === 0) {
                    setIsComplete(true)
                }
            }
        }


    }, [skillSections, temp])

    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch(setQuizPoints(points))
        let temp = submit
        temp[ind] = true
        setSubmit(temp)
        setPoints(0)
        setValue(0)
    };
    const retryQuiz = () => {
        dispatch(setQuizPoints(0))
        setSubmit([])
        setAnswers([])
        setIsComplete(false)
        setInd(0)
        setPoints(0)
        setValue(0)
    }
    useEffect(() => {
        setTimeout(() => {
            setQuestionFlag(true)
        }, 10)
    }, [[...answers]])
    return (
        content ?
            <>

                <ChatBot data={content} pageName={"Skill Section"} />
                {
                    !isComplete &&
                    <button
                        onClick={openModal}
                        className="journal-button-section"
                    >
                        {
                            showModal ?
                                <CloseIcon fontSize="large" />
                                :
                                <FontAwesomeIcon style={{ fontSize: '1.8rem', color: '#ffc324', marginTop: '-2px' }} icon={faBook} />
                        }
                    </button>
                }
                {/* <TranslateModal openTranslate={openTranslate} setOpenTranslate={setOpenTranslate} /> */}
                {
                    showModal &&
                    <div className="journal-modal">
                        <Notes />
                    </div>
                }
                <SkillsBar page="Quiz" />
                <div className="skills-details-container">
                    <Grid container>
                        <Grid container>
                            <Grid xs={12} sm={8} container sx={{ paddingLeft: "2rem", mt: 2, display: { sm: 'flex', xs: 'none' }, justifyContent: { sm: 'flex-start', xs: 'center' } }}>
                                <img src="/assets/img/rockland-logo.webp" style={{ width: "180px" }} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <div className="row text-center m-0 p-0">
                    </div>
                    {
                        isComplete ?
                            <Feedback retry={retryQuiz} nextLink="/quiz-result" />
                            :
                            <>
                                <Box className="row" sx={{ mt: { sm: 0, xs: 3 }, height: { sm: '82vh', xs: "fit-content" }, justifyContent: 'center', alignItems: 'center', overflowY: 'auto' }}>

                                    <form
                                        style={{
                                            width: 'fit-content',
                                            minWidth: isMobile ? "95%" : '50%',
                                            maxWidth: isMobile ? "95%" : '65%',
                                            padding: isMobile ? "10px" : '10px 20px',
                                            borderRadius: '16px',
                                            border: '1px solid grey',
                                            fontSize: '16px',
                                            textAlign: 'left',
                                        }}
                                    >
                                        <ProgressBar style={{
                                            height: '20px',
                                            marginTop: '12px',
                                            padding: '0px'
                                        }}
                                            className="my-progrss"
                                            now={(ind + 1) / content?.length * 100}
                                            label={`${((ind + 1) / content?.length * 100).toFixed(0)}%`}
                                        />
                                        <FormControl sx={{ m: { sm: 3, xs: 0 } }} variant="standard">
                                            <FormLabel id="demo-error-radios"><p className="text-primary1" style={{ fontSize: { sm: '16px', xs: "12px" }, fontWeight: '600' }}>Q{ind + 1}.  {content[ind]?.question}</p></FormLabel>
                                            <RadioGroup
                                                aria-labelledby="demo-error-radios"
                                                name={content[ind]?._id}
                                                value={answers[ind] || null}
                                                onChange={(event) => {
                                                    setQuestionFlag(false)
                                                    setValue(event.target.value);
                                                    let temp = answers;
                                                    temp[ind] = event.target.value;
                                                    setAnswers(temp)
                                                    content[ind]?.trueOption.toString() === event.target.value.toString() ? setPoints(content[ind]?.points) : setPoints(0)

                                                }}
                                            >
                                                <FormControlLabel value={1} control={<Radio
                                                    sx={{
                                                        color: '#0a2876',
                                                        '&.Mui-checked': {
                                                            color: content[ind]?.trueOption === 1 && submit[ind] ? '#4bb543' : '#0a2876',
                                                        },
                                                    }}

                                                    disabled={submit[ind] && content[ind]?.trueOption !== 1 && answers[ind] == "1"}
                                                />} label={!questionFlag ? "" : <>
                                                    {content[ind]?.answer01}
                                                    {
                                                        content[ind]?.trueOption === 1 && submit[ind] && answers[ind] == "1" &&
                                                        <DoneIcon style={{ fontSize: '25px', color: "#4bb543" }} />
                                                    }
                                                    {
                                                        submit[ind] && answers[ind] === "1" && content[ind]?.trueOption !== 1 &&
                                                        <CloseIcon style={{ fontSize: '25px', color: "red" }} />
                                                    }
                                                </>} />
                                                <FormControlLabel value={2} control={<Radio
                                                    sx={{
                                                        color: '#0a2876',
                                                        '&.Mui-checked': {
                                                            color: content[ind]?.trueOption === 2 && submit[ind] ? '#4bb543' : '#0a2876',
                                                        },
                                                    }}
                                                    disabled={submit[ind] && content[ind]?.trueOption !== 2 && answers[ind] == "2"}
                                                />} label={
                                                    !questionFlag ? ""
                                                        :
                                                        <>
                                                            {content[ind]?.answer02}
                                                            {
                                                                content[ind]?.trueOption === 2 && submit[ind] && answers[ind] == "2" &&
                                                                <DoneIcon style={{ fontSize: '25px', color: "#4bb543" }} />
                                                            }
                                                            {
                                                                submit[ind] && answers[ind] === "2" && content[ind]?.trueOption !== 2 &&
                                                                <CloseIcon style={{ fontSize: '25px', color: "red" }} />
                                                            }
                                                        </>
                                                } />
                                                {
                                                    (content[ind]?.answer03 !== "null" && content[ind]?.answer03.trim() !== "") &&
                                                    <FormControlLabel value={3} control={<Radio
                                                        sx={{
                                                            color: '#0a2876',
                                                            '&.Mui-checked': {
                                                                color: content[ind]?.trueOption === 3 && submit[ind] ? '#4bb543' : '#0a2876',
                                                            },
                                                        }}
                                                        disabled={submit[ind] && content[ind]?.trueOption !== 3 && answers[ind] == "3"}
                                                    />} label={
                                                        !questionFlag ? ""
                                                            :
                                                            <>
                                                                {content[ind]?.answer03}
                                                                {
                                                                    content[ind]?.trueOption === 3 && submit[ind] && answers[ind] == "3" &&
                                                                    <DoneIcon style={{ fontSize: '25px', color: "#4bb543" }} />
                                                                }
                                                                {
                                                                    submit[ind] && answers[ind] === "3" && content[ind]?.trueOption !== 3 &&
                                                                    <CloseIcon style={{ fontSize: '25px', color: "red" }} />
                                                                }
                                                            </>
                                                    } />
                                                }
                                                {
                                                    (content[ind]?.answer04 !== "null" && content[ind]?.answer04.trim() !== "") &&
                                                    <FormControlLabel value={4} control={<Radio
                                                        sx={{
                                                            color: '#0a2876',
                                                            '&.Mui-checked': {
                                                                color: content[ind]?.trueOption === 4 ? '#4bb543' : '#0a2876',
                                                            },
                                                        }}
                                                        disabled={submit[ind] && content[ind]?.trueOption !== 4 && answers[ind] == "4"}
                                                    />} label={
                                                        !questionFlag ? ""
                                                            :
                                                            <>
                                                                {content[ind]?.answer04}
                                                                {
                                                                    content[ind]?.trueOption === 4 && submit[ind] && answers[ind] == "4" &&
                                                                    <DoneIcon style={{ fontSize: '25px', color: "#4bb543" }} />
                                                                }
                                                                {
                                                                    submit[ind] && answers[ind] === "4" && content[ind]?.trueOption !== 4 &&
                                                                    <CloseIcon style={{ fontSize: '25px', color: "red" }} />
                                                                }
                                                            </>
                                                    } />
                                                }
                                            </RadioGroup>
                                        </FormControl>
                                        <button className="next"
                                            disabled={value === 0 && !submit[ind]}
                                            onClick={(e) => {
                                                setQuestionFlag(false)
                                                e.preventDefault()
                                                if (submit[ind]) {
                                                    if (content[ind].trueOption != answers[ind]) {
                                                        return
                                                    }
                                                    else {
                                                        content[ind + 1] ? setInd(ind + 1) : <>{skillComplete ? navigate("/lesson?id=" + moduleID) : setIsComplete(true)}</>
                                                    }
                                                } else {

                                                    handleSubmit(e)
                                                }
                                            }}>
                                            <img src={nextSrc} onMouseEnter={() => { setNextSrc("../../assets/svg/next.svg") }} onMouseOut={() => { setNextSrc("../../assets/svg/next-outlined.svg") }} alt="" />
                                        </button>
                                    </form>
                                </Box>
                                <div className="prev" onClick={() => {
                                    let temp = skillSections?.filter(ss => {
                                        return (
                                            ss?.name === "SIMS")
                                    })
                                    if (ind === 0 && !temp.length) {
                                        window.history.go(-2)
                                        return
                                    }
                                    ind === 0 ? window.history.go(-1) : setInd(ind - 1)

                                }}>
                                    <img src={backSrc} onMouseEnter={() => { setBackSrc("../../assets/svg/prev.svg") }} onMouseOut={() => { setBackSrc("../../assets/svg/prev-outlined.svg") }} alt="" />
                                </div>
                            </>
                    }

                </div>
            </> : <>

                <SkillsBar page="Quiz" />
                <h2 className="mt-5 pt-5 text-center">{t("global.noResultFound")}</h2>
            </>
    )
}

