import React, { useState, useEffect } from "react";
import SkillsBar from "./SkillsBar";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import SkillSection from "../details/SkillSection";
import { Grid, Box } from "@mui/material";
import ChatBot from "../../common/chatbot";
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { isTimerEnabled } from '../../common/isTimerEnabled'

function CircularProgressWithLabel(props) {
    return (
        <Box className="next" sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    variant="caption"
                    component="div"
                    sx={{ color: (theme) => theme.palette.primary.main }}
                >{props.label}</Typography>
            </Box>
        </Box>
    );
}


export default function SkillAssess() {
    const skillSections = useSelector((state) => state.user.skillSections);
    const navigate = useNavigate();
    const [nextSrc, setNextSrc] = useState("../../assets/svg/next-outlined.svg")
    const [backSrc, setBackSrc] = useState("../../assets/svg/prev-outlined.svg")
    const [assessContent, setAssessContent] = useState([]);
    const [ind, setInd] = useState(0);
    const [nextTimer, setNextTimer] = useState(60)
    useEffect(() => {
        const timer = setInterval(() => {
            setNextTimer((prevProgress) => (prevProgress > 0 ? prevProgress - 1 : 0));
        }, 1000);
        return () => {
            clearInterval(timer);
        };
    }, [])

    useEffect(() => {
        let temp = skillSections?.filter((ss) => {
            return ss?.name === "Assess";
        });

        setAssessContent(temp);
        if (temp.length === 0) {
            navigate("/skill-detail-sims");
        }
    }, [skillSections, ind]);

    return (
        <>
            {/* <RewardsModal open={open} setOpen={setOpen} />
                <TranslateModal openTranslate={openTranslate} setOpenTranslate={setOpenTranslate} /> */}
            <SkillsBar page="Skill Detail Assess" />
            <div className="skills-details-container">
                <Grid container>
                    <Grid container>
                        <Grid xs={12} sm={8} container sx={{ paddingLeft: "2rem", mt: 2, display: { sm: 'flex', xs: 'none' }, justifyContent: { sm: 'flex-start', xs: 'center' } }}>
                            <img src="/assets/img/rockland-logo.webp" style={{ width: "180px" }} />
                        </Grid>
                        {/* <Grid container xs={12} sm={4} justifyContent="flex-end">
                                <Grid item container justifyContent="flex-end" alignItems="center">
                                    <div className="translate-buttons" style={{ marginRight: "1rem" }}>

                                        <Link to="/" className="translate-it"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setOpenTranslate(true)
                                                log({
                                                    pageName: 'Alt Space-vr',
                                                    buttonName: 'Translate It'
                                                })
                                            }}>
                                            Translate It
                                        </Link>
                                    </div>
                                </Grid>
                            </Grid> */}
                    </Grid>
                </Grid>
                <div
                    className="row justify-content-center mt-3"

                    style={{ width: "100%", overflowY: "auto", overflowX: "hidden", paddingLeft: "1rem" }}
                >
                    <SkillSection content={assessContent[ind]} />
                    {
                        nextTimer > 0 && isTimerEnabled(assessContent[ind]) ?
                            <CircularProgressWithLabel style={{ color: (theme) => theme.palette.primary.main }} label={nextTimer} value={parseInt(nextTimer * 1.67)} />
                            :
                            <button
                                className="next"
                                onClick={() => {
                                    setNextTimer(60)
                                    assessContent[ind + 1]
                                        ? setInd(ind + 1)
                                        : navigate("/skill-detail-sims");
                                }}
                            >
                                <img src={nextSrc} onMouseEnter={() => { setNextSrc("../../assets/svg/next.svg") }} onMouseOut={() => { setNextSrc("../../assets/svg/next-outlined.svg") }} alt="" />
                            </button>
                    }
                </div>
                <div
                    className="prev"
                    onClick={() => {
                        ind === 0 ? window.history.go(-1) : setInd(ind - 1);
                    }}
                >
                    <img src={backSrc} onMouseEnter={() => { setBackSrc("../../assets/svg/prev.svg") }} onMouseOut={() => { setBackSrc("../../assets/svg/prev-outlined.svg") }} alt="" />
                </div>

            </div>
            <ChatBot data={{ name: skillSections[ind]?.allContent?.name, description: skillSections[ind]?.allContent?.description }} pageName={"Skill Section"} />
        </>
    );
}
