import React, { useState, useEffect } from 'react'
import SkillsBar from './SkillsBar'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import SkillSection from '../details/SkillSection'
import { setSession } from '../../../redux-features/user/userSlice'
import { Grid, Box } from "@mui/material"
import ChatBot from '../../common/chatbot'
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { isTimerEnabled } from '../../common/isTimerEnabled'

function CircularProgressWithLabel(props) {
    return (
        <Box className="next" sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    variant="caption"
                    component="div"
                    sx={{ color: (theme) => theme.palette.primary.main }}
                >{props.label}</Typography>
            </Box>
        </Box>
    );
}
export default function SkillSims() {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [nextSrc, setNextSrc] = useState("../../assets/svg/next.svg")
    const [backSrc, setBackSrc] = useState("../../assets/svg/prev-outlined.svg")
    const skillSections = useSelector(state => state.user.skillSections)
    const [ind, setInd] = useState(0)
    const [simsContent, setSimsContent] = useState([])

    const [nextTimer, setNextTimer] = useState(60)
    useEffect(() => {
        const timer = setInterval(() => {
            setNextTimer((prevProgress) => (prevProgress > 0 ? prevProgress - 1 : 0));
        }, 1000);
        return () => {
            clearInterval(timer);
        };
    }, [])

    useEffect(() => {
        let temp = skillSections?.filter(ss => { return (ss?.name === "SIMS") })
        setSimsContent(temp)
        if (temp.length === 0) {
            navigate("/skill-detail-quiz")
        }
    }, [skillSections])

    return (
        <>
            <SkillsBar page="Skill Detail Sims" />
            <div className="skills-details-container" style={{ paddingBottom: "0" }}>
                <Grid container>
                    <Grid container>
                        <Grid xs={12} sm={8} container sx={{ paddingLeft: "2rem", mt: 2, display: { sm: 'flex', xs: 'none' }, justifyContent: { sm: 'flex-start', xs: 'center' } }}>
                            <img src="/assets/img/rockland-logo.webp" style={{ width: "180px" }} />
                        </Grid>
                    </Grid>
                </Grid>
                <Box className="row justify-content-center mt-3 sidebarr" style={{ margin: {sm: "0 0.5rem", xs: "5px"}, width: "100%", overflowX: "hidden" }}>
                    <SkillSection content={simsContent[ind]} />
                    {
                        nextTimer > 0 && isTimerEnabled(simsContent[ind]) ?
                            <CircularProgressWithLabel style={{ color: (theme) => theme.palette.primary.main }} label={nextTimer} value={parseInt(nextTimer * 1.67)} />
                            :
                            <button className="next" onClick={() => {
                                setNextTimer(60)
                                simsContent[ind + 1] ? setInd(ind + 1)
                                    :
                                    <>
                                        {
                                            dispatch(setSession(simsContent[ind]._id))}
                                        {
                                            navigate("/skill-detail-quiz")
                                        }
                                    </>
                            }}>
                                <img src={nextSrc} onMouseEnter={() => { setNextSrc("../../assets/svg/next.svg") }} onMouseOut={() => { setNextSrc("../../assets/svg/next-outlined.svg") }} alt="" />
                            </button>
                    }
                </Box>
                <div className="prev" onClick={() => {
                    ind === 0 ? window.history.go(-1) : setInd(ind - 1)
                }}>
                    <img src={backSrc} onMouseEnter={() => { setBackSrc("../../assets/svg/prev.svg") }} onMouseOut={() => { setBackSrc("../../assets/svg/prev-outlined.svg") }} alt="" />
                </div>

            </div>
            <ChatBot data={{ name: skillSections[ind]?.allContent?.name, description: skillSections[ind]?.allContent?.description }} pageName={"Skill Section"} />
        </>
    )
}
