import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
// Slick Slider
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Button } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
//Other
import Error from "./components/other/Error";
import NotFound from "./components/other/404";
//modules
import Modules from "./components/modules/Modules";
//lessons
import Lesson from "./components/lessons/Lesson";
import SkillsDetails from "./components/lessons/skills/SkillsDetails";
import SkillAssess from "./components/lessons/skills/SkillAssess";
import SkillSims from "./components/lessons/skills/SkillSims";
import SkillQuiz from "./components/lessons/skills/SkillQuiz";
import QuizResult from "./components/lessons/results/QuizResult";
import Loading from "./components/common/Loading";
import Subskills from "./components/lessons/skills/Subskills";
//react-query modules  and functions
import { useQuery } from "react-query";
import {
  getAllData,
} from "./components/_api/index";
import {
  setData,
} from "./redux-features/user/userSlice";

import { useDispatch, useSelector } from "react-redux";
import { Toaster } from "react-hot-toast";
import PreviewCheck from "./components/home/PreviewCheck";
//Google Analytics Intergration
import ReactGA from "react-ga";

import { useTranslation } from 'react-i18next'
import Footer from "./components/common/Footer";
import HelpFooter from "./components/common/helpFooter";
import QRScaned from "./components/qr-scaned";


const TRACKING_ID = process.env.REACT_APP_GA_TID; //Google Analytics Tracking ID
ReactGA.initialize(TRACKING_ID);
function App() {
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const lang = localStorage.getItem("lang")
  const [showPrintPage, setShowPrintPage] = useState(false);
  const { isLoading: dataLoading } = useQuery(
    ["fetching-all-data", lang],
    () => getAllData(lang),
    {
      onSuccess: async (response) => {
        dispatch(setData(response));
      },
    }
  );

  useEffect(() => {
    localStorage.setItem("lang", "en")
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  const printPage = () => {
    window.print({
      pageSetup: {
        size: "legal",
      },
      backgrounds: true,
      backgroundGraphics: true,
    });
  };

  let clarityKey = process.env.REACT_APP_CLARITY_KEY
  useEffect(() => {
    (function (c, l, a, r, i, t, y) {
      c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
      t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
      y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
    })(window, document, "clarity", "script", clarityKey);
  }, [clarityKey])


  // if (dataLoading || loading)
  //   return <Loading />;
  // if (isMobile) {
  //   return (<TurnPhoneH content={t("turn.gamemode")} />)
  // }
  return (
    <>
      {/* <div style={{ ...printPageStyle, width: showPrintPage ? 80 : 30 }}>
        <Button
          onMouseEnter={() => setShowPrintPage(true)}
          onMouseLeave={() => setShowPrintPage(false)}
          style={btnStyle}
          onClick={() => printPage()}
          startIcon={<PrintIcon />}
        >
          {t("Print Page")}
        </Button>
      </div> */}
      <Toaster position="top-center" reverseOrder={false} />
      <Routes>
      <Route exact path="/scaned-url" element={<QRScaned  />} />
        <Route exact path="/module" element={
          <>
            <Modules />
            <Footer />
          </>
        } />
        <Route exact path="/" element={
          <>
            <PreviewCheck />
          </>
        } />
        <Route exact path="/error" element={
          <>
            <Error />
          </>
        } />
        <Route exact path="/lesson" element={
          <>
            <Lesson />
            <Footer pl={"100px"} pr={"50px"} />
          </>
        } />
        <Route exact path="/skills" element={
          <>
            <Subskills />
            <Footer pl={"100px"} pr={"50px"} />
          </>
        } />
        <Route exact path="/skill-detail" element={
          <>
            <SkillsDetails />
          </>
        } />
        <Route exact path="/skill-detail-assess" element={
          <>
            <SkillAssess />
          </>
        } />
        <Route exact path="/skill-detail-sims" element={
          <>
            <SkillSims />
          </>
        } />
        <Route exact path="/skill-detail-quiz" element={
          <>
            <SkillQuiz />
          </>
        } />
        <Route exact path="/quiz-result" element={
          <>
            <QuizResult />
          </>
        } />
        <Route path="*" element={
          <>
            <NotFound />
          </>
        } />
      </Routes>
      {/* <HelpFooter /> */}
    </>
  );
}

export default App;

const printPageStyle = {
  position: "fixed",
  zIndex: 9999,
  bottom: "300px",
  right: 0,
  transition: "all 0.5s ease-in-out",
};
const btnStyle = {
  color: "#616ded",
  width: "fit-content",
  height: "fit-content",
  fontSize: "6px",
  fontWeight: 700,
  textTransform: "capitalize",
  padding: 10,
  background: "#fff",
  boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.1)",
};