import React, { useState } from 'react'
import { Box, useMediaQuery, Typography, Button } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import RecordVideo from './video-recorder'
import { uploadToStickS3 } from './S3Services';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
export default function QRScaned() {
    const [isUpload, setIsUpload] = useState(true)
    const [fileUrl, setFileUrl] = useState("")
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    const handleUpload = async (e) => {
        let res = await uploadToStickS3(e.target.files[0])
        setFileUrl(res)
    }

    if (!isMobile) return <Box sx={{ width: "100%", mt: 5, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
        <Typography variant="h6" color="initial">
            This page is created for mobiles only. Please scan the QR with Mobile to continue...
        </Typography>
        <img width={400} src="./assets/img/qr.png" alt="QR Code" />
    </Box>
    if (isUpload) return (
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100vh", gap: "20px" }}>
            <Button variant='contained' sx={{ borderRadius: "10px", textTransform: "capitalize" }} onClick={() => setIsUpload(false)}>
                <Typography variant="h6" color="light" fontWeight={700}>
                    Record Yourself ?
                </Typography>
            </Button>
            <Button variant='outlined' sx={btnStyle} >
                <Typography variant="h6" color="light" fontWeight={700}>
                    {fileUrl ? "Update Video" : "Upload Video"}
                </Typography>
                <input accept='video/mp4' type="file" style={invisibleInput} onChange={handleUpload} />
            </Button>
            <video controls width="95%" src={process.env.REACT_APP_S3_BUCKET_LINK + "the-stick/" + fileUrl}></video>
        </Box>
    )
    return (
        <>
            <Button sx={{ mt: 5, ml: 2 }} startIcon={<ArrowBackIcon />} onClick={() => setIsUpload(true)}>
                Back
            </Button>
            <RecordVideo />
        </>
    )
}

const btnStyle = {
    width: "fit-content",
    textTransform: "capitalize",
    borderRadius: "10px",
    position: "relative"
}

const invisibleInput = {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    opacity: 0,
    cursor: "pointer"
}