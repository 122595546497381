import React, { useState, useEffect } from 'react';
import {
    Grid,
    Slide,
    Card,
    CardHeader,
    CardContent,
    Divider,
    Typography,
    IconButton,
    Button
} from '@mui/material'
import { addTodo, updateTodo, deleteTodo, prioritizeTodos } from '../../../redux-features/todos/todoSlice'

import Box from '@mui/material/Box';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import toast from 'react-hot-toast'
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    background: isDragging ? "#eee" : "#fff",
    ...draggableStyle,
});
const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "lightblue" : "#f4f5f7",
    width: "100%",
    maxWidth: '100%',
    // minHeight: 300,
});

export default function TodoList({ page: listPage }) {
    const dispatch = useDispatch()
    const [value, setValue] = useState()
    const [isPriorityChanged, setIsPriorityChanged] = useState(false)
    const [list, setList] = useState([])
    let data = useSelector(state => state.todo.todos)


    function onDragEnd(result) {
        const { source, destination } = result;
        if (!destination) {
            return;
        }
        const destIndex = destination.index
        const srcIndex = source.index
        if (srcIndex === destIndex) {
            return;
        }
        setIsPriorityChanged(true)
        let updatedList = []
        if (destIndex < srcIndex) {
            updatedList = list.map((item, ind) => {
                if (ind === destIndex) {
                    return { ...item, priorityIndex: ind + 1 }
                }
                if (ind === srcIndex) {
                    return { ...item, priorityIndex: destIndex }
                }
                if (ind > destIndex) {
                    return { ...item, priorityIndex: ind + 1 }
                } else {
                    return item
                }
            })
        } else {
            updatedList = list.map((item, ind) => {
                if (ind === destIndex) {
                    return { ...item, priorityIndex: ind - 1 }
                }
                if (ind === srcIndex) {
                    return { ...item, priorityIndex: destIndex }
                }
                if (ind < destIndex && ind > srcIndex) {
                    return { ...item, priorityIndex: ind - 1 }
                } else {
                    return item
                }
            })
        }

        setList(updatedList.sort((a, b) => a.priorityIndex - b.priorityIndex))
    }


    useEffect(() => {
        setList([...data]?.sort((a, b) => a.priorityIndex - b.priorityIndex) || [])
        // setList(data)
    }, [data])
    const handleAddTodo = () => {
        if (value?.trim() !== "") {
            let id = uuidv4()
            setList((pre) => [...pre, { _id: id, title: value, priorityIndex: pre?.length }])
            dispatch(addTodo(
                {
                    _id: id,
                    title: value,
                    priorityIndex: list?.length
                }
            ))
            setValue("")
        }
    }

    return (
        <Slide direction="left" in={listPage === "todoList"} mountOnEnter unmountOnExit>
            <Grid sx={mainPageStyle}>
                <Card sx={cardStyle}>
                    <CardHeader sx={{ textAlign: 'center' }} title="To do List" />
                    <Divider />
                    <CardContent sx={{ p: { sm: "16px", xs: 0 } }}>
                        <Box sx={{ my: 3, padding: '2px', display: 'flex', justifyContent: 'space-between', borderRadius: '5px', overflow: 'hidden', border: '1px solid #ccc' }}>
                            <input
                                value={value}
                                onChange={(e) => setValue(e.target.value)}
                                style={{ border: 'none', outline: 'none', width: '90%', margin: '5px 0px 5px 10px' }}
                                type="text" placeholder='Add new item...'
                                onKeyDown={(e) => {
                                    if (e.keyCode === 13) {
                                        handleAddTodo()
                                    }
                                }}
                            />
                            <button
                                disabled={!value}
                                onClick={handleAddTodo}
                                style={{ background: '#0a2876', width: '10%', border: '1px solid #ccc', outline: 'none', color: 'white' }}>+</button>
                        </Box>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable
                                style={{
                                    backgroundColor: "#f4f5f7",

                                    padding: "10px",
                                    width: 300,
                                }}
                                droppableId={`IdDrop`}
                            >
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        style={{ ...getListStyle(snapshot.isDraggingOver), width: '100%', height: '455px', overflow: 'auto', border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>


                                        {
                                            !list?.length ?
                                                <Typography>No to dos added yet. Please add one</Typography>
                                                :
                                                list?.map((row) => (
                                                    <Draggable
                                                        key={row?.index}
                                                        draggableId={`${row?.priorityIndex}`}
                                                        index={row.priorityIndex}
                                                    >
                                                        {(provided, snapshot) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={getItemStyle(
                                                                    snapshot.isDragging,
                                                                    provided.draggableProps.style,
                                                                )}>
                                                                <Row key={row.priorityIndex} row={row} list={list} setList={setList} />
                                                            </div>
                                                        )}
                                                    </Draggable >
                                                ))}
                                        {
                                            isPriorityChanged &&
                                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', }}>
                                                <Button onClick={() => {
                                                    toast.loading("Please wait...")
                                                    dispatch(prioritizeTodos(list))
                                                    toast.dismiss()
                                                    toast.success("Saved")
                                                    setIsPriorityChanged(false)
                                                }} variant='contained' sx={{ background: '#0a2876', m: 1, mt: 3 }} >Save</Button>
                                            </Box>
                                        }
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </CardContent>
                </Card>
            </Grid>
        </Slide >
    );
}


const mainPageStyle = {
    background: '#ecedf3',
}
const cardStyle = {
    m: { md: '20px 70px', xs: "2px" }
}




function Row(props) {
    const dispatch = useDispatch()
    const { row, list, setList } = props;
    const [isEdit, setIsEdit] = useState(false)
    const [value, setValue] = useState(row.title)

    const hanleUpdate = () => {
        if (value.trim() !== "") {
            setList(list.map(item => {
                if (item._id === row._id) {
                    return { ...item, title: value }
                } else {
                    return item
                }
            }))
            setIsEdit(false)
            dispatch(updateTodo({ title: value, _id: row._id }))
        }
    }

    const handleDelete = () => {
        setList(list?.filter(item => item._id !== row._id))
        dispatch(deleteTodo(row._id))
    }

    return (
        <>
            <Box
                sx={{
                    my: 0.5,
                    p: { sm: '2px 10px', xs: '2px' },
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderRadius: '5px', overflow: 'hidden',
                    border: '1px solid #ccc'
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <DragIndicatorOutlinedIcon />
                    {
                        isEdit ?
                            <input
                                value={value}
                                onChange={(e) => setValue(e.target.value)}
                                style={{ width: '100%', padding: '2px 10px' }}
                                type="text" placeholder='Update item...' />
                            :
                            <Typography sx={{ ml: 1 }}>{row.title}</Typography>
                    }
                </Box>
                <Box sx={{ display: 'flex', gap: '10px', padding: '2px' }}>
                    {
                        isEdit ?
                            <>
                                <IconButton onClick={hanleUpdate} sx={{ color: 'black', background: '#3bff00' }}><DoneIcon size="small" /></IconButton>
                                <IconButton onClick={() => setIsEdit(false)} sx={{ color: '#000', background: '#aaaaaa' }}><CloseIcon /></IconButton>
                            </>
                            :
                            <>
                                <IconButton onClick={handleDelete} sx={{ color: 'red' }}><DeleteIcon /></IconButton>
                                <IconButton onClick={() => setIsEdit(true)} sx={{ color: '#0a2876' }}><BorderColorIcon /></IconButton>
                            </>
                    }
                </Box>
            </Box>
        </>
    );
}