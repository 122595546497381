import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setSkillSections, setIDs, setChatbot, setSubSkillName, setSubSkillDescription } from '../../../redux-features/user/userSlice'
import { Grid, Typography, Button } from '@mui/material'
import { QuillEditor } from './quil-editor'
import { useTranslation } from 'react-i18next'


export default function SkillOne(props) {
    const { t } = useTranslation()
    const userCompleted = useSelector((state) => state?.user?.userCompleted);
    const dispatch = useDispatch()
    const { skill, moduleID, lessonID, skillID } = props;
    const navigate = useNavigate()

    const handleEnrollment = (skill) => {
        dispatch(setSubSkillName(skill?.name))
        dispatch(setSubSkillDescription(skill?.description))
        const header = {
            module: moduleID,
            lesson: lessonID,
            skill: skillID,
            subSkill: skill?._id
        }
        dispatch(setChatbot(skill?.chatBot))
        dispatch(setSkillSections({
            skillSections: skill?.skillSections,
            quiz: skill?.quiz
        }));
        dispatch(setIDs(header))
        if (skill?.skillSections?.filter(section => { return (section.name === "Intro" && userCompleted?.includes(section._id)) })?.length !== skill?.skillSections.filter(section => { return (section.name === 'Intro') })?.length) {
            navigate('/skill-detail')
        } else if (skill?.skillSections?.filter(section => { return (section.name === "Assess" && userCompleted?.includes(section._id)) })?.length !== skill?.skillSections.filter(section => { return (section.name === 'Assess') })?.length) {
            navigate('/skill-detail-assess')
        } else if (skill?.skillSections?.filter(section => { return (section.name === "SIMS" && userCompleted?.includes(section._id)) })?.length !== skill?.skillSections.filter(section => { return (section.name === 'SIMS') })?.length) {
            navigate('/skill-detail-sims')
        } else {
            navigate('/skill-detail')
        }
    }


    return (
        <>
            <Grid container justifyContent="space-between" item className="subskillredesign__singleskill" sx={{ width: { sm: '400px', xs: '100%' } }}>

                <Grid>
                    <Grid item container xs={12} justifyContent="flex-start" alignItems="center" className="subskillredesign__singleskillinner" p={2} sx={{ borderRadius: "1rem", flexWrap: "nowrap" }}>
                        <Grid item xs={12}>
                            <Typography className="skilloneheading" sx={{ fontWeight: "700" }}>{skill?.name}</Typography>
                        </Grid>
                    </Grid>
                    <QuillEditor value={skill?.description} readOnly={true} className="quillllls" />
                </Grid>

                <Grid>
                    <Grid item justfiyContent="center" container mt={2}>
                        <Button
                            onClick={() => handleEnrollment(skill)}
                            className="skillonebtn"
                            sx={{
                                color: userCompleted?.includes(skill._id)
                                    ? "#829d00" : "#0a2876",
                                background: userCompleted?.includes(skill._id)
                                    ? "rgb(229, 254, 235)" : "#FAFBFF", width: "100%"
                            }}>
                            {userCompleted?.includes(skill._id) ? t("global.completed") : t("module.btnStarted")}
                        </Button>
                    </Grid>
                </Grid>

            </Grid>
        </>
    )
}
