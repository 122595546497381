import {
    Box, InputAdornment, Modal, TextField,
    MenuItem, Button, Menu, IconButton, Typography, Grid, useMediaQuery
} from "@mui/material"
import { useTheme } from "@mui/material/styles";
import SendIcon from '@mui/icons-material/Send';
import "./index.css"
import { sendMessageToGpt } from "../_api/chat-bot";
import { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { useTranslation } from 'react-i18next'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import toast from "react-hot-toast";
import Tutorial from './page-tutorials'

const ChatBot = ({ data, pageName }) => {
    const [chatBotModal, setChatBotModal] = useState(false)
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

    return (
        <div style={{ position: "relative", }}>
            <ChatBotBody setChatBotModal={setChatBotModal} data={data} pageName={pageName} chatBotModal={chatBotModal} />
            <div className={pageName === "Skill Section" ? "skill-section-chat-btn" : "chat-btn-2"}
                style={{
                    boxShadow: "0px 0px 20px 10px rgba(255,255,255, 0.2)"
                }}
                onClick={() => {
                    setChatBotModal(true)
                }}
            >
                <img width={isMobile ? "30px" : "40px"} src={"../../assets/img/bot/bot-icon.svg"} alt="" />
            </div>
        </div>
    )
}
export const ChatBotBody = ({ setChatBotModal, chatBotModal, data, pageName }) => {

    const moduleName = useSelector((state) => state?.user?.moduleName);
    const lessonName = useSelector((state) => state?.user?.lessonName);
    const skillName = useSelector((state) => state?.user?.skillName);
    const subSkillName = useSelector((state) => state?.user?.subSkillName);
    const subSkillDescription = useSelector((state) => state?.user?.subSkillDescription);
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
    const { t } = useTranslation()
    const messagesEndRef = useRef()

    const [messages, setMessages] = useState([]);
    const [inputText, setInputText] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [gradeNumber, setGradeNumber] = useState(12)
    const [anchorEl, setAnchorEl] = useState(null);
    const [isFullScreen, setIsFullScreen] = useState(true);
    const handlesendMessage = async () => {
        if (!inputText.trim()) return;

        const newMessages = [...messages, { sender: 'user', message: inputText }];
        setMessages(newMessages);
        setInputText('');

        try {
            setIsLoading(true)
            const botReply = await sendMessageToGpt(
                newMessages,
                pageName,
                data,
                gradeNumber,
                moduleName,
                lessonName,
                skillName,
                subSkillName,
                subSkillDescription
            ); // Pass newMessages as an argument
            setIsLoading(false)
            setMessages([...newMessages, { sender: 'assistant', message: botReply }]);
            scrollToBottom()

        } catch (error) {
            setIsLoading(false)
            console.error('Error sending message:', error);
            setChatBotModal(false)
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
            });
        }
    };


    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    const open = Boolean(anchorEl);


    useEffect(() => {
        setGradeNumber(localStorage.getItem("gradeNumber") || 12)
    }, [])

    return (
        <Modal
            open={chatBotModal}
            onClose={() => setChatBotModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className={isFullScreen ? 'chatbot-container-fullscreen' : 'chatbot-container'}>
                {/* header */}
                <Box sx={{ minWidth: { md: 800, sm: 500, xs: "100%" } }} className='chatbot-header-container'>
                    <div style={{ display: "flex", gap: 6, justifyContent: 'center', alignItems: "center" }}>
                        <div className="chat-bot-profile-pic">
                            <img width={isMobile ? "40px" : "50px"} src="../../assets/img/bot/bot-icon.svg" alt="" />
                        </div>
                        <div style={{ fontSize: isMobile ? "12px" : "1rem", display: "flex", flexDirection: "column" }} >
                            <b>{t("askAnything")}</b>
                            <span style={{ margin: 0 }}>{t("online")}</span>
                        </div>
                        <Box sx={boxStyle}>
                            <Button
                                endIcon={<KeyboardArrowDownIcon />}
                                onClick={(event) => setAnchorEl(event.currentTarget)}
                                variant="outlined"
                                color="inherit"
                                fontSize="inherit"
                                sx={readnessButtonStyle}
                            >
                                {t("gradeLevel") + " " + gradeNumber}
                            </Button>
                            <IconButton
                                onClick={() => {
                                    toast.success(`${t("gradeUpdatedMessage")} ${gradeNumber}`)
                                    localStorage.setItem("gradeNumber", gradeNumber)
                                }}
                                sx={{ borderRadius: '12px', px: { sm: 1.5, xs: .5 } }}
                            >
                                <DoneRoundedIcon sx={{color: "#ffc324"}} />
                            </IconButton>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={() => setAnchorEl(null)}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                {
                                    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(item => {
                                        return (
                                            <MenuItem key={item} sx={itemStyle}
                                                onClick={() => {
                                                    setAnchorEl(null)
                                                    setGradeNumber(item)
                                                }}>
                                                {item == gradeNumber && <DoneRoundedIcon color="primary" sx={{ mr: 1 }} />} {t("grade")} {item}
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Menu>
                        </Box>
                    </div>
                    <span
                        style={{ cursor: "pointer", marginRight: '5px' }}
                        onClick={() => {
                            setChatBotModal(false)
                        }}
                    >❌</span>

                </Box>
                {/* body */}
                <Box sx={{
                    justifyContent: !messages.length && "center",
                    height: isFullScreen ? "100%" : "400px",
                    overflowY: "auto",
                    WebkitOverflowScrolling: "touch", // Enable momentum scrolling for iOS
                    "&::-webkit-scrollbar": {
                        width: "5px", // Adjust the width of the scrollbar
                    },
                    "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "rgba(0, 0, 0, 0.3)", // Adjust the color of the scrollbar thumb
                        borderRadius: "5px", // Adjust the border radius of the scrollbar thumb
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust the color of the scrollbar thumb on hover
                    },
                    "&::-webkit-scrollbar-track": {
                        backgroundColor: "transparent", // Set the background color of the scrollbar track to transparent
                    },
                }} className='chatbot-body-container'>
                    {
                        !messages.length && <div className="col-12 d-flex justify-content-center align-items-center">
                            <Grid container spacing={3}
                                sx={{ width: "70%", mt: 1 }}
                            >
                                <Grid sm={6} xs={12}
                                    sx={{
                                        borderRadius: "10px",
                                        p: { sm: '10px', xs: "5px" },
                                        gap: { sm: '20px', xs: "10px" },
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: 'center',
                                        backgroundColor: "#ffc324",
                                        overflow: "hidden"
                                    }}
                                >
                                    <img width="35%" src="https://stickball.s3.amazonaws.com/bot-icon-white.svg" alt="" />
                                    <Typography sx={{ fontWeight: 700, color: 'white', fontSize: { xs: "18px", md: "35px" }, gap: "1rem", maxWidth: { sm: "170px", xs: '100px' } }} vriant="h4">
                                        {t("askAnything")}
                                    </Typography>
                                </Grid>
                                <Grid sm={6} xs={12}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center"
                                    }}
                                >
                                    <Typography variant="body1" color="initial"
                                        sx={{
                                            ml: { sm: 3, xs: 0 },
                                            mt: { sm: 0, xs: 2 },
                                            fontWeight: { sm: 600, xs: 400 },
                                            fontSize: { sm: '1.5rem', xs: '0.9rem' }
                                        }}

                                    >
                                        {t("emptyChatText1")}
                                        {/* <br />
                                        <br />
                                        {t("emptyChatText2")} */}
                                        {/* <br />
                                        <br />
                                        {t("emptyChatText3")} */}
                                    </Typography>
                                </Grid>
                            </Grid>
                            {/* <img width="41%" src="../assets/svg/no-data-2.png" alt="" /> */}
                        </div>
                    }
                    {messages?.map((val) => (
                        <>
                            <EachMessage isLoading={isLoading} val={val} />
                        </>
                    ))}
                    {isLoading && <EachMessage val={{ sender: 'assistant', message: t("typing") + "...", }} />}
                    <div ref={messagesEndRef} />
                </Box>
                <div className='chatbot-header-container' style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
                    <Tutorial page="askAnything" />
                    <TextField
                        size="small"
                        placeholder={t("enterQuestion")}
                        value={inputText}
                        onChange={(e) => setInputText(e.target.value)}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                handlesendMessage();
                            }
                        }}

                        id="outlined-basic"
                        sx={{
                            width: "90%",
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                        }}
                        variant="outlined"
                        InputProps={{
                            sx: { borderRadius: 50 },
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SendIcon sx={{ cursor: "pointer", color: "#ffc324" }} onClick={handlesendMessage} />
                                </InputAdornment>
                            ),
                        }}
                    />
                    {
                        !isMobile &&
                        <IconButton
                            onClick={() => setIsFullScreen(!isFullScreen)}
                        >
                            {
                                isFullScreen ?
                                    <FullscreenExitIcon sx={{ width: 35, height: 35 }} />
                                    :
                                    <FullscreenIcon sx={{ width: 35, height: 35 }} />
                            }
                        </IconButton>
                    }
                </div>
            </div >
        </Modal >
    )
}
const EachMessage = ({ val }) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
    const botBorderRadius = "0px 14px 14px 14px"
    const userBorderRadius = "14px 0px 14px 14px"
    const isBot = val.sender == "assistant";
    return (
        <>
            <div className={isBot ? "each-message" : "each-message-user"}>
                <div className="chat-bot-profile-pic" style={{ padding: 0, background: !isBot && "white" }}>
                    {isBot && <img width={isMobile ? "30px" : "50px"} src={"../../assets/img/bot/bot-icon.svg"} alt="" />}
                    {!isBot && <img width={isMobile ? "30px" : "50px"} src={"../../assets/img/bot/chatbot-user.png"} alt="" />}

                </div>
                <Box
                    sx={{
                        display: "flex",
                        padding: { sm: "10px 25px", xs: "5px 10px" },
                        borderRadius: isBot ? botBorderRadius : userBorderRadius,
                        backgroundColor: isBot ? "#0a2876" : "lightgray",
                        maxWidth: { sm: '50%', xs: "70%" },
                        width: "fit-content",
                        position: "relative",
                        top: { sm: "28px", xs: "16px" },
                        color: isBot ? "white" : "black",
                        alignItems: 'center'
                    }}>
                    <p className="chat-bot-message-text">{val?.message}</p>
                </Box>
            </div>
        </>
    )
}


export default ChatBot;

const readnessButtonStyle = {
    fontSize: 'inherit',
    color: 'black',
    textTransform: 'none',
    borderRadius: "12px",
    padding: { sm: '10px 20px', xs: '8px' },
    background: 'white',
    border: '1px solid #D8D7D7'
}

const boxStyle = {
    fontSize: { sm: '1rem', xs: "12px" },
    display: 'flex',
    width: 'fit-content',
    backgroundColor: '#616DED1A',
    borderRadius: "12px",
    gap: { sm: '5px', xs: "2px" },
    ml: { sm: 5, xs: 1 }
}
const itemStyle = {
    width: '170px',
    mx: '5px',
    borderRadius: '12px',
    '&:hover': {
        backgroundColor: '#0a2876',
        color: 'white'
    }
}