const config = {
    palette: {
        primary: {
            main: "#0a2876",
            light: "#bacbf7",
            contrastText: '#fff',
        },
        secondary: {
            main: "#ffc324",
            light: "#fae8b9",
            contrastText: "#fff",
        },
        success: {
            main: "#61B83C",
            light: "#b2fc92",
            dark: "#247701",
            contrastText: "#fff",
        },
        light: {
            main: "#fff"
        }
    }
}

export default config