import React, { useState, useEffect } from 'react'
import SkillsBar from './SkillsBar'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import SkillSection from '../details/SkillSection'
import { Grid, Box } from "@mui/material";
import ChatBot from '../../common/chatbot'
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { isTimerEnabled } from '../../common/isTimerEnabled'

function CircularProgressWithLabel(props) {
    return (
        <Box className="next" sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    variant="caption"
                    component="div"
                    sx={{ color: (theme) => theme.palette.primary.main }}
                >{props.label}</Typography>
            </Box>
        </Box>
    );
}


export default function SkillsDetails() {
    const navigate = useNavigate();
    const skillSections = useSelector(state => state.user?.skillSections)
    const [ind, setInd] = useState(0)
    const [nextSrc, setNextSrc] = useState("../../assets/svg/next-outlined.svg")
    const [backSrc, setBackSrc] = useState("../../assets/svg/prev-outlined.svg")
    const [introContent, setIntroContent] = useState([])
    const [nextTimer, setNextTimer] = useState(60)
    useEffect(() => {
        let temp = skillSections?.filter(ss => { return (ss?.name === "Intro") })
        setIntroContent(temp)
        if (temp.length === 0) {
            navigate("/skill-detail-assess")
        }
    }, [skillSections])

    useEffect(() => {
        const timer = setInterval(() => {
            setNextTimer((prevProgress) => (prevProgress > 0 ? prevProgress - 1 : 0));
        }, 1000);
        return () => {
            clearInterval(timer);
        };
    }, [])

    return (
        <>
            <SkillsBar page="Skill Detail" />
            <div className="skills-details-container" style={{ overflowX: "hidden" }}>
                <Grid item container justifyContent="space-between" alignItems="center">
                    <Grid xs={12} sm={8} container sx={{ paddingLeft: "2rem", mt: 2, display: { sm: 'flex', xs: 'none' }, justifyContent: { sm: 'flex-start', xs: 'center' } }}>
                        <img src="/assets/img/rockland-logo.webp" style={{ width: "180px" }} />
                    </Grid>
                </Grid>
                <div className="row text-center m-0 p-0">
                </div>
                {
                    !introContent.length ?
                        <div className="col-12 d-flex justify-content-center align-items-center p-0">
                            <img width="30%" src="../assets/svg/lesson/no-data2.svg" alt="" />
                        </div>
                        :
                        <>
                            <Box className="skillsectioncontainer row justify-content-center mt-3 aaaaaa gridcontianer" sx={{ margin: { sm: "0 0.5rem", xs: 0 }, width: "100%", height: "fit-content" }}>
                                <SkillSection content={introContent[ind]} />
                                {
                                    nextTimer > 0 && isTimerEnabled(introContent[ind]) ?
                                        <CircularProgressWithLabel style={{ color: (theme) => theme.palette.primary.main }} label={nextTimer} value={parseInt(nextTimer * 1.67)} />
                                        :
                                        <button className="next" onClick={() => {
                                            setNextTimer(60)
                                            introContent[ind + 1] ? setInd(ind + 1) : navigate("/skill-detail-assess")
                                        }}>
                                            <img src={nextSrc} onMouseEnter={() => { setNextSrc("../../assets/svg/next.svg") }} onMouseOut={() => { setNextSrc("../../assets/svg/next-outlined.svg") }} alt="" />
                                        </button>
                                }
                            </Box>
                        </>
                }

                <div className="prev"
                    onClick={() => {
                        ind === 0 ? window.history.go(-1) : setInd(ind - 1)
                    }}>
                    <img src={backSrc} onMouseEnter={() => { setBackSrc("../../assets/svg/prev.svg") }} onMouseOut={() => { setBackSrc("../../assets/svg/prev-outlined.svg") }} alt="" />
                </div>
            </div>
            <ChatBot data={{ name: skillSections[ind]?.allContent?.name, description: skillSections[ind]?.allContent?.description }} pageName={"Skill Section"} />
        </>
    )
}