import 'react-quill/dist/quill.snow.css';
import { styled } from '@mui/material/styles';
import ReactQuill from 'react-quill'

const QuillEditorRoot = styled(ReactQuill)(({ }) => ({
  border: 0,
  height: "fit-content",
  disabled: 'true',
  padding: '0px',
  borderColor: '#0a2876',
  borderRadius: '16px',
  borderStyle: 'solid',
  display: 'flex',
  flexDirection: 'column',
  '& .ql-snow.ql-toolbar': {
    display: 'none',
    borderColor: '#0a2876',
    borderLeft: 'none',
    borderRight: 'none',
    borderTop: 'none',
    textAlign: 'left',
    '& .ql-picker-label:hover': {
      color: '#0a2876',
    },
    '& .ql-picker-label.ql-active': {
      color: '#0a2876'
    },
    '& .ql-picker-item:hover': {
      color: '#0a2876'
    },
    '& .ql-picker-item.ql-selected': {
      color: '#0a2876'
    },
    '& button:hover': {
      color: '#0a2876',
      '& .ql-stroke': {
        color: '#0a2876'
      }
    },
    '& button:focus': {
      color: '#0a2876',
      '& .ql-stroke': {
        color: '#0a2876',
      }
    },
    '& button.ql-active': {
      '& .ql-stroke': {
        color: '#0a2876'
      }
    },
    '& .ql-stroke': {
      color: '#0a2876'
    },
    '& .ql-picker': {
      color: '#0a2876'
    },
    '& .ql-picker-options': {
      backgroundColor: '#000000',
      border: 'none',
      borderRadius: '16px',
      padding: '2px'
    }
  },
  '& .ql-snow.ql-container': {
    borderBottom: 'none',
    borderColor: '#0a2876',
    borderLeft: 'none',
    borderRight: 'none',
    flexGrow: 1,
    overflow: 'hidden',

    '& .ql-editor': {
      padding: '2px',
      fontSize: '16px',
      '&.ql-blank::before': {
        color: '#000000',
        fontStyle: 'normal',
        left: '2px',
        fontSize: '16px'
      }
    }
  }
}));

const AdminQuillEditorRoot = styled(ReactQuill)(({ }) => ({
  border: 1,
  borderStyle: 'solid',
  display: 'flex',
  flexDirection: 'column',
  
  '& .ql-snow.ql-toolbar': {
    borderLeft: 'none',
    borderRight: 'none',
    borderTop: 'none',

    '& .ql-picker-label:hover': {
    },
    '& .ql-picker-label.ql-active': {
    },
    '& .ql-picker-item:hover': {
    },
    '& .ql-picker-item.ql-selected': {
    },
    '& button:hover': {
      '& .ql-stroke': {
      }
    },
    '& button:focus': {
      '& .ql-stroke': {
      }
    },
    '& button.ql-active': {
      '& .ql-stroke': {
      }
    },
    '& .ql-stroke': {
    },
    '& .ql-picker': {
    },
    '& .ql-picker-options': {
      border: 'none',
    }
  },
  '& .ql-snow.ql-container': {
    borderBottom: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    flexGrow: 1,
    overflow: 'hidden',
    '& .ql-editor': {
      '&.ql-blank::before': {
        fontStyle: 'normal',
      }
    }
  }
}));

const modules1 = {
  toolbar: [
    [{ 'font': [] }],
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'align': [] }],
    [{ 'direction': 'rtl' }],
    ['bold', 'italic', 'underline'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
  ],
}

const modules = {
  toolbar: [
    [{ 'font': [] }],
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'align': [] }],
    [{ 'direction': 'rtl' }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ 'color': [] }, { 'background': [] }],
    [{ 'list': 'ordered' }, { 'list': 'bullet' },
    ],
    ['clean']
  ],
}
export const QuillEditor = (props) => (
  <QuillEditorRoot modules={modules} {...props} />
);

export const AdminQuillEditor = (props) => (
  <AdminQuillEditorRoot modules={props.quiz ? modules1 : modules} {...props} />
);
