import React, { useState } from 'react'
import { Box, Button, Typography, Grid } from "@mui/material"
import { useTranslation } from 'react-i18next';

export default function MayorMessage({ setMayor, setOpen }) {
    const { t } = useTranslation()

    const [isVideoEnabled, setVideoEnabled] = useState(false)
    const [showButton, setShowButton] = useState(false)
    return (
        <Box sx={mayorMessage}>
            {
                isVideoEnabled ?
                    <Box sx={{ width: "100%", height: "90%", display: "flex", flexDirection: "column",alignItems: "center", backgroundColor: "rgba(255,255,255,0.2)" }}>
                        <video
                            onEnded={() => {
                                setShowButton(true)
                            }}
                            controls
                            autoPlay
                            style={{ height: '90%'}}
                            playsInline
                            alt="All the devices"
                            src={"https://stickball.s3.amazonaws.com/IMG_2354.MOV"}
                        ></video>
                        {
                            showButton &&
                            <Button sx={btnGetStarted} onClick={() => {
                                setMayor(false)
                                setOpen(true)
                            }}>
                                <Typography sx={{ fontSize: "15px", color: "white", textAlign: "center", }}>Start Tutorial</Typography>
                            </Button>
                        }
                    </Box>
                    :
                    <Grid container sx={{ display: "flex", flexWrap: { md: "nowrap" }, alignItems: "center" }}>
                        <Grid item xs={12} sx={mayorLeft}>
                            <Typography variant="h4" sx={{ color: "#fff", textAlign: "center", fontSize: "2.5rem" }}>Click to Watch the Video from Mayor</Typography>
                            <Button sx={btnGetStarted} onClick={() => setVideoEnabled(true)}>
                                <Typography sx={{ fontSize: "15px", color: "white", textAlign: "center", }}>{t("module.btnStarted")}</Typography>
                            </Button>
                        </Grid>

                        <Grid xs={12} sx={mayorImageBox}>
                            <img style={imgStyle} src="assets/img/mayor.png" alt="" />
                        </Grid>
                    </Grid>
            }
        </Box>
    )
}

const mayorMessage = {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
}

const mayorLeft = {
    backgroundColor: theme => theme.palette.primary.main,
    py: 7,
    mt: { md: "250px" },
    height: "fit-content",
    zIndex: 2,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    mr: { md: "-20%" },
    order: { md: 1, xs: 2 }
}

const imgStyle = {
    width: "100%",
    height: "auto",
    borderRadius: "50%",
    backgroundColor: "#fff"
}

const mayorImageBox = {
    order: { md: 2, xs: 1 },
    ml: { md: "10%" },
    height: "fit-content",
    borderRadius: "50%",
    p: 2,
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.3)"
}
const btnGetStarted = {
    mt: 4,
    background: (theme) => theme.palette.secondary.main,
    borderRadius: "0.6rem",
    color: "white",
    "&:hover": { background: (theme) => theme.palette.secondary.dark, },
    px: 10
}