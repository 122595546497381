/*----------------------------------------------------------------
Redux and persist imports
----------------------------------------------------------------*/
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import userReducer from '../redux-features/user/userSlice'
import todoReducer from '../redux-features/todos/todoSlice'
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';


/*----------------------------------------------------------------
Redux with persist
----------------------------------------------------------------*/

const rootPersistConfig = {
    key: 'root',
    storage,
}

const userPersistConfig = {
    key: 'user',
    storage,
}
const todoPersistConfig = {
    key: 'todo',
    storage,
}

const rootReducer = combineReducers({
    user: persistReducer(userPersistConfig, userReducer),
    todo: persistReducer(todoPersistConfig, todoReducer),
})

const persistedReducer = persistReducer(rootPersistConfig, rootReducer)

/*----------------------------------------------------------------
Store with persist configration
----------------------------------------------------------------*/
export const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk]
})

export const persistor = persistStore(store)