import axios from 'axios';

export const getAssignments = async (type, language) => {
    try {
        if (language == "sp") {
            const res = await axios.get("/assets/json/skill-assignment/spanish.json")
            const data2 = res.data.data
            const filteredData = data2.filter((val) => (
                val.partName === type
            ));
            res.data.data = filteredData
            return res.data
        }
        const res = await axios.get("/assets/json/skill-assignment/english.json")
        console.log("Assignments Response: ", res)
        const data2 = res.data.data
        const filteredData = data2.filter((val) => (
            val.partName === type
        ));
        res.data.data = filteredData
        return res.data
    } catch (err) {
        return err
    }
}